import {colors} from "@c10h/colors";
import {ListOption} from "@components/OptionsList";
import {FocusRing} from "@react-aria/focus";
import {useFocus} from "@react-aria/interactions";
import {useMenuItem} from "@react-aria/menu";
import {mergeProps} from "@react-aria/utils";
import {TreeState} from "@react-stately/tree";
import {Node} from "@react-types/shared";
import React from "react";

type MenuItemProps = {
  item: Node<ListOption<string>>;
  state: TreeState<ListOption<string>>;
  onAction: (key: React.Key) => void;
  // @ts-expect-error TS7010: 'onClose', which lacks return-type annotation, implicitly has an 'any' return type.
  onClose();
};

const MenuItem: React.FC<MenuItemProps> = ({item, state, onAction, onClose}) => {
  // Get props for the menu item element
  const ref = React.useRef();
  const {menuItemProps} = useMenuItem(
    {
      key: item.key,
      onAction,
      onClose,
    },
    state,
    // @ts-expect-error TS2345: Argument of type 'MutableRefObject<undefined>' is not assignable to parameter of type 'RefObject<HTMLElement>'.
    ref,
  );

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  const [isFocused, setFocused] = React.useState(false);
  const {focusProps} = useFocus({onFocusChange: setFocused});

  const [selectedKey] = state.selectionManager.selectedKeys;
  const isSelected = item.key === selectedKey;

  return (
    <FocusRing focusRingClass="focus-ring">
      <li
        {...mergeProps(menuItemProps, focusProps)}
        // @ts-expect-error TS2322: Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLLIElement> | undefined'.
        ref={ref}
        className={`aria-focus cp df aic jcsb fs16 lh15 ph6 pv3 br8 ${
          isFocused || isSelected ? "font-isb" : "font-ir"
        } ${isSelected ? "contrast-tb" : ""}`}
        style={{
          backgroundColor: isSelected
            ? colors.darkGreen
            : isFocused
            ? `${colors.gray800}10`
            : "transparent",
          color: isSelected ? "white" : colors.gray800,
        }}
        data-cy="lng"
      >
        {item.rendered}
        {isSelected && <span aria-hidden className="cIcon-check white" />}
      </li>
    </FocusRing>
  );
};

export default React.memo(MenuItem);
