import MenuItem from "@components/components/Menu/MenuItem";
import {AnchorPosition} from "@components/components/Menu/types";
import {ListOption} from "@components/OptionsList";
import {FocusScope} from "@react-aria/focus";
import {AriaMenuOptions, useMenu} from "@react-aria/menu";
import {DismissButton, useOverlay} from "@react-aria/overlays";
import {mergeProps} from "@react-aria/utils";
import {MenuTriggerState} from "@react-stately/menu";
import {TreeProps, useTreeState} from "@react-stately/tree";
import React from "react";

import {positions} from "./constants";
import {MenuButtonProps} from "./MenuButton";

export type MenuPopupProps = MenuButtonProps &
  AriaMenuOptions<ListOption<string>> & {
    domProps: React.HTMLAttributes<HTMLElement>;
    autoFocus: MenuTriggerState["focusStrategy"];
    // @ts-expect-error TS7010: 'onClose', which lacks return-type annotation, implicitly has an 'any' return type.
    onClose();
    anchorDirection: AnchorPosition;
  };

const MenuPopup: React.FC<MenuPopupProps> = props => {
  const state = useTreeState({...props, selectionMode: "single"} as unknown as TreeProps<
    ListOption<string>
  >);

  const ref = React.useRef();
  // @ts-expect-error TS2345: Argument of type 'MutableRefObject<undefined>' is not assignable to parameter of type 'RefObject<HTMLElement>'.
  const {menuProps} = useMenu(props, state, ref);

  const overlayRef = React.useRef();
  const {overlayProps} = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    // @ts-expect-error TS2345: Argument of type 'MutableRefObject<undefined>' is not assignable to parameter of type 'RefObject<HTMLElement>'.
    overlayRef,
  );

  return (
    <FocusScope restoreFocus>
      {/* @ts-expect-error TS2322: Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'. */}
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <div
          className="pos-a p6 bg-gray50 br5 minw18 mt2 bs2 zIndex2 contrast-tb"
          style={{
            ...positions[props.anchorDirection],
          }}
        >
          <h2 className="gray800 fs16 lh15 ph6 mb2">{props.label}</h2>
          {/* @ts-expect-error TS2322: Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLUListElement> | undefined'. */}
          <ul {...mergeProps(menuProps, props.domProps)} ref={ref}>
            {[...state.collection].map(item => (
              <MenuItem
                key={item.key}
                item={item}
                state={state}
                // @ts-expect-error TS2322: Type '((key: Key) => void) | undefined' is not assignable to type '(key: Key) => void'.
                onAction={props.onAction}
                onClose={props.onClose}
              />
            ))}
          </ul>
        </div>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

export default React.memo(MenuPopup);
