import {Item} from "@components/Footer/Footer";
import SectionHeader from "@components/Footer/SectionHeader";
import {keyedSectionLink} from "@components/Footer/SectionLink";
import React from "react";

type Props = {
  header: string;
  items: Item[];
};

const Column: React.FC<Props> = ({header, items}) => (
  <div className="mr20 mb10">
    <SectionHeader text={header} />
    <ul className="dg gafr gg6 mt8">{items.map(keyedSectionLink)}</ul>
  </div>
);

export default Column;
