import Column from "@components/Footer/Column";
import {ItemGroup} from "@components/Footer/Footer";
import SplitLayout from "@components/Footer/SplitLayout";
import {useTranslation} from "ni18n";
import React from "react";

type Props = {
  sections: ItemGroup[];
};

const Middle: React.FC<Props> = ({sections}) => {
  const leftSections = sections.filter(section => !section.isOnRight);
  const rightSections = sections.filter(section => section.isOnRight);
  const i18n = useTranslation();

  return (
    <nav aria-label={i18n.t("Footer Navigation")}>
      <SplitLayout className="pb10">
        <div className="df fw">
          {leftSections.map(section => (
            <Column key={section.header} header={section.header} items={section.items} />
          ))}
        </div>
        <div className="df jcsb fdc fx2">
          <div className="dg gafc gg10 ">
            {rightSections.map(section => (
              <Column key={section.header} header={section.header} items={section.items} />
            ))}
          </div>
          {/* <Newsletter /> */}
        </div>
      </SplitLayout>
    </nav>
  );
};

export default Middle;
