import {uniqBy} from "lodash";

import {
  LocationFragment,
  getRegionsFromLocationsBySpecialtyIds,
} from "../components/v5/Region/utils/getRegionsFromLocationsBySpecialtyIds";
import hardcodedVirtualRegions from "../constants/hardcodedVirtualRegions";

export type Region = {
  key: string;
  name: string;
  textValue: string;
  slug: string;
};

export const generateRegionData = (
  locations: LocationFragment[],
  specialtyIds?: string[],
  hideVirtualOnly = false,
): Region[] => {
  const regions = [
    ...getRegionsFromLocationsBySpecialtyIds(locations, specialtyIds),
    ...(hideVirtualOnly ? [] : hardcodedVirtualRegions),
  ];
  return uniqBy(
    regions.map(region => ({
      key: region.slug,
      textValue: region.name,
      name: region.name,
      slug: region.slug,
    })),
    region => region.slug,
  );
};
