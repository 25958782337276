import {TreeItem} from "@components/components/Tree/ComplexTreeWrapper";
import TreeOverlay from "@components/components/Tree/TreeOverlay";
import {ButtonVariant} from "@components/components/Tree/types";
import TextInput from "@components/Input/TextInput";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";

import ComplexTreeWrapper from "./ComplexTreeWrapper";

interface TreeViewProps {
  initialSelected?: TreeItem;
  initialExpandedItems: string[];
  selectorName: string;
  searchLabel: string;
  searchPlaceholder: string;
  buildTree: (searchTerm: string) => TreeItem<string>;
  handleSelect: (id: string | number) => unknown;
  handleSearch?: (searchTerm?: string, tree?: TreeItem<string>, currentRoute?: string) => void;
  buttonVariant: ButtonVariant;
}

export const TreeView: React.FC<TreeViewProps> = ({
  buildTree,
  initialExpandedItems,
  initialSelected,
  selectorName,
  searchLabel,
  searchPlaceholder,
  handleSelect,
  handleSearch,
  buttonVariant,
}) => {
  const [selectedId, setSelectedId] = useState(initialSelected?.id);
  const [searchTerm, setSearch] = useState("");

  const currentRoute = useCurrentRoute();

  const tree = useMemo(() => buildTree(searchTerm), [buildTree, searchTerm]);
  const handleClose = useCallback(() => setSearch(""), []);

  const handleTreeItemSelect = (close: () => void) => (item: TreeItem<string>) => {
    setSelectedId(item.id);
    handleSelect(item.id);
    close();
  };

  useEffect(() => {
    if (handleSearch) handleSearch(searchTerm, tree, currentRoute);
  }, [currentRoute, handleSearch, searchTerm, tree]);

  useEffect(() => {
    setSelectedId(initialSelected?.id);
  }, [initialSelected?.id]);

  return (
    <TreeOverlay
      selectedItem={typeof initialSelected?.data === "string" ? initialSelected?.data : ""}
      selectorName={selectorName}
      onClose={handleClose}
      variant={buttonVariant}
      renderChildren={close => (
        <div className="bg-white br5 bs2 p-6 contrast-tb">
          <TextInput
            onChange={setSearch}
            icon="cIcon-search -ml-2 mr1 fs28-f"
            placeholder={searchPlaceholder}
            ariaLabel={searchLabel}
            autoFocus
            autoComplete="off"
          />
          <ComplexTreeWrapper
            onSelect={handleTreeItemSelect(close)}
            tree={tree}
            defaultExpandedItems={initialExpandedItems}
            defaultSelectedItem={selectedId || ""}
          />
        </div>
      )}
    />
  );
};

export default React.memo(TreeView);
