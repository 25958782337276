import {elCaminoLocIds} from "src/constants/locations";

import {elCaminoDisabledTime} from "../../../constants/LocationConstants";
import {isInTimeRange} from "../../../utils/timeUtils";

export const isBookingDisabled = (id: string): boolean => {
  const isElCaminoAndAfterSep5 = elCaminoLocIds.includes(id) && isInTimeRange(elCaminoDisabledTime);

  return isElCaminoAndAfterSep5;
};
