import {UrlObject} from "url";

import Link from "next/link";
import React from "react";

type Props = {
  text: string;
  href: string | UrlObject;
};

export const SectionLink: React.FC<Props> = ({text, href}) => (
  <li>
    <Link
      href={href}
      className="gray600 font-im hover-font-isb hover-gray800 t-150 focus-bsDarkBlue3 pv2 semiBoldHover"
      style={{"--br": 0.2}}
      data-text={text}
      data-cy="footer-link"
    >
      {text}
    </Link>
  </li>
);

export const keyedSectionLink = ({text, href}: Props) => (
  <SectionLink key={text} text={text} href={href} />
);
