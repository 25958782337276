import {colors} from "@c10h/colors";
import {useTranslation} from "ni18n";
import React, {ReactElement, useEffect, useRef} from "react";
import {useV5ImgSrc} from "src/useV5ImgSrc";

import {transparent} from "../../utils/transparent";
import {highZIndexMap} from "../../utils/zIndexMap";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  active: boolean;
  onClickOutside: () => void;
  img?: string;
  innerClasses?: string;
  shouldAutoFocus?: boolean;
  closeFocusRef?: React.MutableRefObject<HTMLSpanElement>;
  closeButtonStyle?: React.CSSProperties;
};

const Modal: React.FC<Props> = ({
  className,
  style,
  active,
  img,
  children,
  onClickOutside,
  innerClasses,
  shouldAutoFocus,
  closeFocusRef,
  closeButtonStyle = {},
}) => {
  const i18n = useTranslation();
  const closeRef = useRef(null);
  const lastItemRef = useRef(null);

  const imgSrc = useV5ImgSrc(img || "");

  useEffect(() => {
    if (shouldAutoFocus && closeRef.current) {
      // @ts-expect-error TS2339: Property 'focus' does not exist on type 'never'.
      closeRef.current.focus();
    }
  }, [active]);

  useEffect(() => {
    const originalBodyStyle = window.getComputedStyle(document.body)?.overflow;
    const originalHtmlStyle = window.getComputedStyle(document.documentElement)?.overflow;

    if (active) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalBodyStyle;
      document.documentElement.style.overflow = originalHtmlStyle;
    };
  }, [active]);

  // Passes the close ref to children for containing the tab order to the modal
  const childrenAsArray = React.Children.toArray(children);
  const childElements = childrenAsArray.map(child =>
    typeof child === "string" || typeof child === "number"
      ? child
      : React.cloneElement(child as ReactElement, {closeRef, lastItemRef}),
  );

  // @ts-expect-error TS7006: Parameter 'e' implicitly has an 'any' type.
  const keyDownHandler = e => {
    if (e.shiftKey && e.key === "Tab" && lastItemRef.current) {
      // @ts-expect-error TS2339: Property 'focus' does not exist on type 'never'.
      lastItemRef.current.focus();
    }
    if (e.key === "Enter") {
      onClickOutside();
      // @ts-expect-error TS2532: Object is possibly 'undefined'.
      if (closeFocusRef.current) {
        // @ts-expect-error TS2532: Object is possibly 'undefined'.
        closeFocusRef.current.focus();
      }
    }
  };

  return active ? (
    <div
      className="w100p h100p top0 left0 tac df fdc aic jcc pos-f"
      style={{
        verticalAlign: "middle",
        backgroundColor: transparent(colors.gray800, 85),
        zIndex: highZIndexMap.NAV_BAR,
      }}
      onClick={onClickOutside}
    >
      <div
        role="dialog"
        className={`pos-r br5 bg-white gray800 ${className || ""}`}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
      >
        <span
          ref={closeRef}
          className="gray800 df jcc aic pos-a-f fs14 br50 p2 cp zIndex1 focus-bsDarkBlue3"
          role="button"
          tabIndex={0}
          style={{
            height: 37,
            width: 37,
            right: 10,
            top: 10,
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            ...closeButtonStyle,
          }}
          onClick={onClickOutside}
          onKeyDown={keyDownHandler}
          aria-label={i18n.t("Close modal")}
        >
          <span className="cIcon-close" />
        </span>
        {img && (
          <img
            src={imgSrc}
            alt={img}
            style={{borderRadius: "20px 20px 0 0", bottom: 1}}
            className="pos-r"
          />
        )}
        <div className={`p10 p8-md p4-xs ${innerClasses || ""}`} style={{...(style || {})}}>
          {childElements}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
