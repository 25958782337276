import {compact} from "lodash";

import {TreeItem, TreeItemId} from "../../components/Tree/ComplexTreeWrapper";

export function flattenSearchResultsForAnalytics(tree: TreeItem<string>): TreeItemId[] {
  const level1 = tree.children?.filter(node => !node.children).map(node => node.id) || [];
  const level2 = compact(tree.children?.map(node => node.children).flat() || []).map(
    node => node.id,
  );
  return [...level1, ...level2];
}
