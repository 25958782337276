/**
 * Useage
 * 
 * import {getCareSlugs} from "src/constants/getCareConstants";
 * 
 * _constants.ts > getCareSlug: "/get-care/[slug]"
 * 
 * href: {
    pathname: v5Pages.getCareSlug,
    query: {
      slug: getCareSlugs.[slug],
    },
  },
 */

export const getCareSlugs = {
  covidCare: "covid-care",
  covidTravelTesting: "covid-19-travel-testing",
  nextDayRtPc: "next-day-rt-pc",
  urgentCare: "urgent-care",
  lgbtqHealthAndWellness: "lgbtq-health-and-wellness",
  womansHealth: "womens-health",
} as const;
