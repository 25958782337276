import {useTranslation} from "ni18n";
import React from "react";
// @ts-expect-error TS7016: Could not find a declaration file for module 'react-outside-click-handler'. '/Users/goksel/Sites/carbon-website-next/node_modules/react-outside-click-handler/index.js' implicitly has an 'any' type.
import OutsideClickHandler from "react-outside-click-handler";

export type ListOption<T = unknown> = {
  text: string | JSX.Element;
  value: T;
  selected?: boolean;
};

export type SectionHeader = {
  header: string;
};

const isSectionHeader = (item: ListOption | SectionHeader): item is SectionHeader =>
  (item as SectionHeader).header !== undefined;

type Props = React.HTMLAttributes<HTMLDivElement> & {
  options: (ListOption | SectionHeader)[];
  onOutsideClick: () => void;
  onSelectOption: (value: any, index: number) => void;
};

const OptionsList = ({
  options,
  onOutsideClick,
  onSelectOption,
  className,
  ...props
}: Props): React.ReactElement => {
  const i18n = useTranslation();

  const onClick = (value: any, i: number) => () => {
    onOutsideClick();
    onSelectOption(value, i);
  };
  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div
        {...props}
        className={`p4 br5 bs2 bg-gray50 font-ir ${className}`}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ol role="tablist" className="basic">
          {options.map((opt, i) =>
            isSectionHeader(opt) ? (
              <div key={`option-${i}`} className="gray800 font-im ml4 mt2 mb4 cd">
                {opt.header}
              </div>
            ) : (
              <li
                key={`option-${i}`}
                className={` br5 mb2 cp font-ir focus-bsDarkBlue3 ${
                  opt.selected ? "bg-darkGreen white fw6 contrast-tb" : "hover-bg-gray100 hover-fw6"
                }`}
                onClick={onClick(opt.value, i)}
                onKeyDown={e => e.key === "Enter" && onSelectOption(opt.value, i)}
                style={{minWidth: 200}}
                role="tab"
                aria-label={i18n.t("Select {{text}}", {text: opt.text})}
                tabIndex={0}
              >
                <div className="df aic jcsb p3 pl4">
                  <span className="lh1">{opt.text}</span>
                  {opt.selected && <span className="cIcon-check fs14" />}
                </div>
              </li>
            ),
          )}
        </ol>
      </div>
    </OutsideClickHandler>
  );
};

export default OptionsList;
