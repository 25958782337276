import states from "us-state-codes";

import {RegionStateMap} from "../constants/regionStateMap";
import {fetchCities} from "./fetchCities";

export const getStateCodeByName = (name = ""): string =>
  states.getStateCodeByStateName(name.replace(/-/g, " ")) || "";

export const getCityAndStatesOptions = async (): Promise<string[]> => {
  const cityAndStates = await fetchCities();

  return cityAndStates
    .sortBy(["state", "city"])
    .map(c => {
      const stCode = states.getStateCodeByStateName(c.state);
      const ci = c.city;
      return [c.state, `${ci}, ${stCode}`];
    })
    .flat()
    .distinct();
};

/**
 *
 * @param cityAndState i.e. "San Francisco, CA" or "California"
 * @returns [city, state]
 */
export const getCityAndState = (
  cityAndState: string,
): [city: string | null, state: string | null] => {
  if (!cityAndState) {
    return [null, null];
  }
  const keyVals = cityAndState.split(",");
  const hasCity = keyVals.length > 1;
  const state = hasCity ? states.getStateNameByStateCode(keyVals[1]) : keyVals[0];
  const city = hasCity ? keyVals[0] : null;
  return [city, state];
};

export const getStateCodeFromRegionSlug = (string: string | unknown): string | null =>
  typeof string === "string" && string in RegionStateMap ? RegionStateMap[string].code : null;
