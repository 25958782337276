import {useEffect, useState} from "react";
import {v4} from "uuid";

/**
 * Returns `undefined` on first client and server render.
 * Use to prevent react hydration errors.
 * @returns a random v4 UUID
 */
export const useClientSideId = () => {
  const [state, setState] = useState<string | undefined>();

  useEffect(() => {
    setState(v4());
  }, []);

  return state;
};
