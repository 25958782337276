import React, {useRef} from "react";

import {useDisclosure} from "../../../../hooks/useDisclosure";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import {useKeyHandler} from "../../../../useEscape";
import {useFocusScope} from "../../../../utils/a11y/useFocusScope";
import {ButtonVariant} from "./types";

const CONTAINER_WIDTH = 350;
const variantProps = {
  primary: {
    className: "brdn br5 fw6 p3 df aic bg-transparent cp aria-focus contrast-tb",
    style: {},
  },
  secondary: {
    className: "brdn br2 fw4 p4 df aic cp aria-focus bg-gray100 jcsb aibl contrast-tb",
    style: {width: CONTAINER_WIDTH},
  },
  locations: {
    className:
      "br2 flex flex-grow w-full items-center cursor-pointer justify-between aibl fs14 bg-gray50 gray600 hover-gray800 p-2 sm:p-4 brd1nc brd-gray100 minw10 contrast-tb",
    style: {maxWidth: CONTAINER_WIDTH},
  },
};

interface TreeOverlayProps {
  selectedItem: string;
  selectorName: string;
  renderChildren: (close: () => void) => React.ReactElement;
  onClose: () => unknown;
  variant: ButtonVariant;
}

export const TreeOverlay: React.FC<TreeOverlayProps> = ({
  onClose,
  selectorName,
  renderChildren,
  selectedItem,
  variant,
}) => {
  const disclosure = useDisclosure();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    onClose();
    disclosure.close();
  };

  useOnClickOutside(ref, handleClose);
  useKeyHandler("Escape", handleClose);

  const setFocusScope = useFocusScope(disclosure.isOpen);

  return (
    <div ref={ref} className="relative inline-block zIndex2 flex-grow" style={{maxWidth: 350}}>
      <div id="react-aria-select-descriptor" className="visually-hidden">
        {selectorName}
      </div>
      <button
        id="tree-popup-trigger"
        onClick={disclosure.toggle}
        aria-labelledby="react-aria-select-descriptor react-aria-selected-item"
        className={
          variantProps[variant].className || "brdn br5 fw6 p3 df aibl bg-transparent cp aria-focus"
        }
        style={variantProps[variant].style}
        data-cy="comboboxButton"
        aria-expanded={disclosure.isOpen}
        aria-controls="tree-popup"
        aria-haspopup="tree"
      >
        <span id="react-aria-selected-item" className="label-lg fs18 fs14-sm lh26 mr2">
          {selectedItem}
        </span>
        <span className="cIcon-dropdown-arrow-down" aria-hidden="true" />
      </button>
      {disclosure.isOpen && (
        <div
          id="tree-popup"
          ref={el => setFocusScope(el)}
          aria-modal
          className="mt2 pos-a zIndex1"
          style={{width: CONTAINER_WIDTH}}
        >
          {renderChildren(handleClose)}
        </div>
      )}
    </div>
  );
};

export default React.memo(TreeOverlay);
