import React from "react";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  children: [React.ReactNode, React.ReactNode];
};

const SplitLayout = ({children, className = "", ...rest}: Props) => (
  <div className={`df jcsb maxw80 ma ph8 ph4-sm ${className}`} {...rest}>
    <div className="fx5">{children[0]}</div>
    <div className="fx minw18 minw0-sm">{children[1]}</div>
  </div>
);

export default SplitLayout;
