import Fade from "@components/Transitions/Fade";
import Link from "next/link";
import React, {useState} from "react";

import {ItemGroup} from "./Footer";

type Props = {
  section: ItemGroup;
  noBorder?: boolean;
};

const DropdownList: React.FC<Props> = ({section, noBorder}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen =
    (boolean = !isOpen) =>
    () => {
      setIsOpen(boolean);
    };

  const buttonTarget = `${section.header.toLowerCase().replace(" ", "-")}-footer-menu`;

  return (
    <li className="ph8 ph4-xs">
      <div className={`brd-gray200 pv3 ${noBorder ? "brdn" : "brdb"}`}>
        <div>
          <h3>
            <button
              className="df jcsb aic w100p bg-transparent brdn focus-bsDarkBlue3-hug pv3 cp ph2 fs20 fw4 font-cm ls-3 gray800"
              style={{"--br": 0.2}}
              onClick={toggleIsOpen()}
              aria-expanded={isOpen}
              aria-controls={buttonTarget}
            >
              {section.header}
              <span
                className={`cIcon-icon_arrow_dropdown fs24 t-100 ${isOpen ? "rotate-180" : ""}`}
                aria-hidden
              />
            </button>
          </h3>
        </div>
        <Fade id={buttonTarget} in={isOpen} duration={100} timeout={0} unmountOnExit collapse>
          <ul className="dg gg6 pv3 gafr">
            {section.items.map(({text, ...rest}) => (
              <li key={text}>
                <Link
                  {...rest}
                  className="gray600 font-im hover-font-isb hover-gray800 p2 focus-bsDarkBlue3-hug ls-3"
                  style={{"--br": 0.2}}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </Fade>
      </div>
    </li>
  );
};

export default DropdownList;
