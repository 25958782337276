import {useEffect, useState} from "react";

import {addListenerSafe, removeListenerSafe} from "../utils/mediaQueries";

const NO_MOTION_PREFERENCE = "(prefers-reduced-motion: no-preference)";

/**
 * Accessbility hook, detects user-prefers-reduced-motion. Default to
 * `true` since we don't know what the user's preference is on the server.
 */
export const usePrefersReducedMotion = (): boolean => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(true);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(NO_MOTION_PREFERENCE);

    setPrefersReducedMotion(!mediaQueryList.matches);

    const listener = (event: MediaQueryListEvent) => {
      setPrefersReducedMotion(!event.matches);
    };

    addListenerSafe(mediaQueryList, listener);

    return () => {
      removeListenerSafe(mediaQueryList, listener);
    };
  }, []);

  return prefersReducedMotion;
};
