import {colors} from "@c10h/colors";
import VirtualLocations from "@components/LocationExplorer/VirtualLocations";
import RegionTreeSelector from "@components/Region/RegionTreeSelector";
import {FeatureFlag} from "@services/featureFlagConstants";
import {useTranslation} from "ni18n";
import React, {useEffect, useState} from "react";
import {SoonestSlotTimeByLocationAndSpecialty} from "src/hooks/useGetSoonestTime";

import hardcodedVirtualRegions from "../../../constants/hardcodedVirtualRegions";
import {useFeatureFlag} from "../../../hooks/useFeatureFlags";
import {RegionSlug, RootStateLocation} from "../../../store/types";
import {fetchCachedSlot} from "../../../utils/fetchCachedSlot";
import {fetchSoonestSlotByLocationsAndSpecialtyIds} from "../../../utils/fetchSoonestSlotByLocationsAndSpecialtyIds";
import {LocationExplorerCard} from "./LocationExplorerCard";

const STARTING_COUNT = 3;
const INC_BY = 6;
const SPECIALTY_COUNT = 10;

export interface LocationExplorerProps {
  locations: RootStateLocation[];
  currentLocationId: string;
  headerText: string;
  initialRegionSlug: RegionSlug;
  isSlotTimeVisible: boolean;
}

export const LocationExplorer: React.FC<LocationExplorerProps> = ({
  locations,
  currentLocationId,
  headerText,
  initialRegionSlug,
  isSlotTimeVisible,
}) => {
  const i18n = useTranslation();

  const [selectedRegion, setSelectedRegion] = useState(initialRegionSlug);
  const [soonestSlots, setSoonestSlots] = useState<SoonestSlotTimeByLocationAndSpecialty>({});
  const [numberOfLocationsToDisplay, setNumberOfLocationsToDisplay] = useState(STARTING_COUNT);

  const onSelectionChange = (key: RegionSlug) => setSelectedRegion(key);
  const handleShowMore = () => setNumberOfLocationsToDisplay(numberOfLocationsToDisplay + INC_BY);
  const handleShowLess = () => setNumberOfLocationsToDisplay(numberOfLocationsToDisplay - INC_BY);
  const enableCache = useFeatureFlag<boolean>(FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED);

  const regionLocations = locations
    .filter(({region}) => region.slug === selectedRegion)
    .discard(({id}) => id === currentLocationId);

  const displayedLocations = regionLocations.slice(0, numberOfLocationsToDisplay);
  const isShowMoreVisible = numberOfLocationsToDisplay < regionLocations.length;
  const isShowLessVisible =
    !isShowMoreVisible &&
    regionLocations.length > STARTING_COUNT &&
    numberOfLocationsToDisplay > STARTING_COUNT;

  // Reset to starting # of displayed locations on region change
  useEffect(() => {
    setNumberOfLocationsToDisplay(STARTING_COUNT);
  }, [selectedRegion]);

  // Fetch slots on update to # of displayed locations or region change
  useEffect(() => {
    if (!isSlotTimeVisible || enableCache === undefined) return;

    // Only fetch slots for locations we haven't fetched slots for yet
    const locationHasSlot = ({id}: RootStateLocation) => soonestSlots.getKeys().includes(id);
    const locationsToFetchSlotsFor = displayedLocations.discard(locationHasSlot);

    if (locationsToFetchSlotsFor.length === 0) return;

    if (enableCache) {
      locationsToFetchSlotsFor.map(({id, specialtyIds}) =>
        specialtyIds
          .map(sId =>
            fetchCachedSlot({locationId: id, specialtyId: sId}).then(slot => [sId, slot?.time]),
          )
          .sequence()
          .then(pars => {
            setSoonestSlots(prevSlots => ({...prevSlots, ...{[id]: pars.toObject()}}));
          }),
      );
    } else {
      fetchSoonestSlotByLocationsAndSpecialtyIds({
        locations: locationsToFetchSlotsFor,
        specialtyCount: SPECIALTY_COUNT,
      }).then(res => setSoonestSlots({...soonestSlots, ...res}));
    }
  }, [numberOfLocationsToDisplay, selectedRegion]);

  if (
    displayedLocations.length === 0 &&
    !hardcodedVirtualRegions.some(({slug}) => slug === selectedRegion)
  )
    return null;

  return (
    <section
      aria-label={headerText}
      className="pv16 ph24 ph4-sm ph32-md contrast-tbt"
      style={{borderColor: `${colors.gray200}70`}}
      id="explore-locations"
    >
      <h2 className="mb4">{headerText}</h2>
      <div className="mh-2">
        <RegionTreeSelector initialRegionSlug={initialRegionSlug} onSelect={onSelectionChange} />
      </div>
      {displayedLocations.length === 0 && <VirtualLocations />}
      <ul className="gtc3fr dg gafr gg24 gg12-xl gg12-lg gg0-md df-md fdc-md pv8">
        {displayedLocations.map(location => (
          <LocationExplorerCard key={location.id} soonestSlots={soonestSlots} location={location} />
        ))}
      </ul>
      {isShowMoreVisible && (
        <button
          onClick={handleShowMore}
          className="br8 cp ma df brdn bg-white aic gray800 font-isb hover-bg-gray100 pv4 ph6"
        >
          {i18n.t("Show more locations")}
          <span className="cIcon-dropdown-arrow-down ml2 gray800" aria-hidden />
        </button>
      )}
      {isShowLessVisible && (
        <button
          onClick={handleShowLess}
          className="br8 cp ma df brdn bg-white aic gray800 font-isb hover-bg-gray100 pv4 ph6"
        >
          {i18n.t("Show less")}
          <span className="cIcon-dropdown-arrow-up ml2 gray800" aria-hidden />
        </button>
      )}
    </section>
  );
};
