import SplitLayout from "@components/Footer/SplitLayout";
import LanguageSelector from "@components/LanguageSelector";
import Link from "next/link";
import {useTranslation} from "ni18n";
import React from "react";
import useMediaQuery from "src/useMediaQuery";

import {useV5ImgSrc} from "../../../useV5ImgSrc";
import {v5Pages} from "../../_common/_constants";

const MinimalFooter: React.VoidFunctionComponent = () => {
  const i18n = useTranslation();
  const isSm = useMediaQuery("sm");

  return (
    <nav aria-label={i18n.t("Footer Links")} className="bg-gray100 w100p contrast-tbt">
      <SplitLayout className="font-ir aic fdcr-sm aifs-sm py-6 gap-2 lg:py-0 lg:min-h-[5rem]">
        <div className="fx3 mt2-sm">
          <div className="gap-6 lg:gap-14 flex flex-col-reverse lg:flex-row">
            <div className="df aic">
              <Link href={{pathname: v5Pages.home}} className="focus-bsDarkBlue3 mr2">
                <img
                  height={30}
                  width={145}
                  src={useV5ImgSrc("/logo", true)}
                  alt="Carbon Health"
                  className="db"
                />
              </Link>
              <span className="gray800 hover-gray800 ">{`© ${new Date().getFullYear()}`}</span>
            </div>
            <div className="flex flex-col gap-[1.2rem] lg:gap-14 lg:items-center lg:flex-row">
              <Link
                href={{pathname: v5Pages.privacy}}
                className="gray600 hover-gray800 focus-bsDarkBlue3 mr0-sm"
                style={{"--br": 0.2}}
              >
                {i18n.t("Privacy Policy")}
              </Link>
              <Link
                href={{pathname: v5Pages.privacyPractices}}
                className="gray600 hover-gray800 focus-bsDarkBlue3 mr0-sm"
                style={{"--br": 0.2}}
              >
                {i18n.t("Notice of Privacy Practices")}
              </Link>
              <Link
                href={{pathname: v5Pages.terms}}
                className="gray600 hover-gray800 focus-bsDarkBlue3"
                style={{"--br": 0.2}}
              >
                {i18n.t("Terms of Service")}
              </Link>
            </div>
          </div>
        </div>
        <div className="fx2">
          <div className="dg gafc gg4 aic maxw20 df-sm fdcr-sm aifs-sm gg2-sm jcfe">
            <span style={{marginLeft: isSm ? -10 : 0}}>
              <LanguageSelector
                className="mr0-f"
                anchorDirection={isSm ? "top left" : "top right"}
              />
            </span>
          </div>
        </div>
      </SplitLayout>
    </nav>
  );
};

export default MinimalFooter;
