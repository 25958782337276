import {useEffect, useState} from "react";

export type ScrollEvent = {
  y: number;
  x: number;
  direction: "up" | "down" | null;
};

const useScroll = (): ScrollEvent => {
  const isDomLoaded = !(typeof window === "undefined" || !window.document);
  const [scroll, setScroll] = useState<ScrollEvent>({
    x: isDomLoaded ? document.body.getBoundingClientRect().left : 0,
    y: isDomLoaded ? document.body.getBoundingClientRect().top : 0,
    direction: null,
  });

  useEffect(() => {
    const listener: EventListener = () => {
      const boundingClientRect = document.body.getBoundingClientRect();

      setScroll(prev => ({
        x: boundingClientRect.left,
        y: boundingClientRect.top,
        direction: prev.y < boundingClientRect.top ? "up" : "down",
      }));
    };
    window.addEventListener("scroll", listener, {passive: true});
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [setScroll]);

  return scroll;
};

export default useScroll;
