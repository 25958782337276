export const positions = {
  "bottom left": {
    top: 42,
    left: 0,
  },
  "bottom right": {
    top: 42,
    right: 0,
  },
  "top left": {
    bottom: 42,
  },
  "top right": {
    bottom: 42,
    right: 0,
  },
};
