import React, {useState} from "react";

import SkipLink, {SkipLinkItem} from "./SkipLink";

type Props = {
  skipLinks: SkipLinkItem[];
};

const SkipLinkBar: React.FC<Props> = ({skipLinks}) => {
  const [focused, setFocused] = useState(false);

  const style = {
    zIndex: 101,
    ...(focused
      ? {}
      : {
          clip: "rect(0 0 0 0)",
          clipPath: "inset(50%)",
          height: 1,
          overflow: "hidden",
          width: 1,
        }),
  };
  return (
    <div
      id="skip-links"
      className="pos-f dg gg2 zIndex2 p1"
      onFocusCapture={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      style={style}
    >
      {skipLinks.map(item => (
        <SkipLink key={`skip-link-${item.text}`} item={item} />
      ))}
    </div>
  );
};

export default SkipLinkBar;
