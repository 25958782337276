import {analytics} from "src/utils/analytics";

export type NavLabel =
  | "Booking"
  | "Covid"
  | "Services"
  | "Locations"
  | "Company"
  | "Menu"
  | "Account"
  | "Insurances"
  | "Languages";

export const trackNavLinkClick = (
  label: NavLabel,
  section: "Menu" | "Footer" | "Navbar",
  value: string,
  labelCopy: string,
): void => {
  analytics.post({
    category: "NavigationDiscovery",
    label,
    action: "Clicked",
    value,
    extraData: {
      section,
      labelcopy: labelCopy,
    },
  });
};
