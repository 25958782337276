import {analytics} from "src/utils/analytics";

import {TreeItem} from "../../components/Tree/ComplexTreeWrapper";
import {flattenSearchResultsForAnalytics} from "./flattenSearchResultsForAnalytics";

export const sendAnalyticsDataOnRegionChange = (region: string, currentRoute: string): void => {
  analytics.post({
    category: analytics.category.LOCATION_DISCOVERY,
    label: analytics.label.REGION_DROP_DOWN,
    action: analytics.action.CLICKED,
    extraData: {
      isMapPopup: false,
      value: region,
      source: currentRoute === "index" ? "footer" : currentRoute,
    },
  });
};

let interval: NodeJS.Timeout | undefined;
export const sendAnalyticsDataOnSearch = (
  searchTerm?: string,
  tree?: TreeItem<string>,
  currentRoute?: string,
): void => {
  if (interval) clearInterval(interval);

  interval = setTimeout(() => {
    const hasSearchTerm = searchTerm?.length;
    if (hasSearchTerm && tree) {
      const searchResults = flattenSearchResultsForAnalytics(tree);

      analytics.post({
        category: analytics.category.LOCATION_DISCOVERY,
        label: analytics.label.REGION,
        action: analytics.action.SEARCHED,
        extraData: {
          options: searchResults,
          searchText: searchTerm,
          resultsCount: searchResults.length,
          source: currentRoute === "index" ? "footer" : currentRoute,
        },
      });
    }
  }, 500);
};
