import {useCallback, useMemo, useState} from "react";

/**
 * State utility for controlling multiple selected items in a list by index.
 * Suitable for when disclosure (close/open) must be lifted to parent.
 * TODO: improve performance of `toggle` and `isActive`
 */
export const useActiveIndexController = <T extends string | number>(initialSelected: T[] = []) => {
  const [state, setState] = useState<T[]>(initialSelected);

  const isActive = useCallback((value: T) => state.includes(value), [state]);

  const toggle = useCallback(
    (index: T) => {
      if (isActive(index)) {
        setState(oldState => oldState.filter(i => i !== index));
      } else {
        setState(oldState => [...oldState, index]);
      }
    },
    [isActive],
  );

  const reset = useCallback(() => setState([]), []);

  return useMemo(
    () => ({
      activeList: state,
      reset,
      toggle,
      isActive,
    }),
    [isActive, reset, state, toggle],
  );
};
