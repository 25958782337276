import moment from "moment-timezone";

import {loadAvailableSlots} from "../_services/api";
import {laTimezone, millisPerMinute} from "../components/_common/_constants";
import {SoonestSlotTimeByLocationAndSpecialty} from "../hooks/useGetSoonestTime";
import {RootStateLocation} from "../store/types";

export const fetchSoonestSlotByLocationsAndSpecialtyIds = ({
  locations,
  specialtyIds,
  daysCount = 21,
  specialtyCount = 1,
}: {
  locations: RootStateLocation[];
  specialtyIds?: string[];
  daysCount?: number;
  specialtyCount?: number;
}): Promise<SoonestSlotTimeByLocationAndSpecialty> => {
  const now = +moment();

  return locations
    .map(l => {
      const sortedSpecialties = l.specialties.vals().sortBy("sortIndex").slice(0, specialtyCount);
      return (specialtyIds || sortedSpecialties.map(s => s.id)) // if specialIds param is null, try with first specialtyId
        .map(sId => {
          const isSpecialtyInList = l.specialtyIds.includes(sId);

          // if location doesn't have ids in specialIds doesn't exist, try with first specialtyId
          const newSpecialtyId = isSpecialtyInList ? sId : sortedSpecialties[0].id;

          const {defaultNewPatientApptDuration: duration = 10, apptLeadTime = 0} =
            l.specialties[newSpecialtyId];

          const from = (apptLeadTime * millisPerMinute || 0) < 0 ? now : now + apptLeadTime; // check if from is in past

          return loadAvailableSlots({
            practiceId: l.practiceId,
            locationId: l.id,
            from,
            to: +moment(now).add(daysCount, "days").endOf("day"),
            duration,
            specialtyId: newSpecialtyId,
            timezone: l.timezone || laTimezone,
          }).then(slotTimes => ({lId: l.id, sId, slotTime: slotTimes?.[0]?.[0]?.time}));
        })
        .sequence();
    })
    .sequence()
    .then(locationsAndSlot =>
      locationsAndSlot
        .flat()
        .groupBy("lId")
        .mapValues(slots => slots.groupBy("sId").mapValues(slots => slots[0].slotTime)),
    );
};
