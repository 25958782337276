import React from "react";

type Props = {
  text: string;
};

const SectionHeader: React.FC<Props> = ({text}) => (
  <span className="ttu font-im text-gray-500 fs13 ls2">{text}</span>
);

export default SectionHeader;
