import {colors} from "@c10h/colors";
import {Trans, useTranslation} from "ni18n";
import React, {ReactElement, useEffect} from "react";
import {useDispatch} from "react-redux";
import {v5Pages} from "src/components/_common/_constants";
import {Btn} from "src/components/v5/V5Comp";

import {actions} from "../store";

type ReturnType = {
  showTravelModal: boolean;
  modalInner: ReactElement;
  modalImage: string;
};

const showTravelModalReferers = [
  // "localhost:5000",
  "southwest.com",
  "alaskaair.com",
  "hawaiianairlines.com",
  "hawaiicovid19.com",
  "san.org",
  "flysanjose.com",
  "flypdx.com",
  "portseattle.org",
];

const pagesWhereHidden = ["covid-19-travel-clearance", "alaska", "travel-clearance"];
const alwaysShowOn = ["covid-19-clearance-exam"];

export const useCovidTravelModalConstants = (
  referer: string | undefined,
  path: string,
): ReturnType => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (referer) {
      // prevent referer check from passing again.
      dispatch(actions.setReferer(null));
    }
  }, []);

  const alwaysShowOnPage =
    path && alwaysShowOn.reduce((acc, next) => acc || path.includes(next), false);

  const hideOnPage =
    path && pagesWhereHidden.reduce((acc, next) => acc || path.includes(next), false);

  const refererCheck =
    referer && showTravelModalReferers.reduce((acc, next) => acc || referer.includes(next), false);

  const showTravelModal = alwaysShowOnPage || (!hideOnPage && refererCheck);

  const modalProps = {
    buttons: [
      {
        children: i18n.t("Find a testing site near you"),
        hoverBg: colors.gray600,
        href: v5Pages.covid19TravelClearance,
      },
    ],
  };

  const modalInner = (
    <div className="df fdc">
      <h1 className="fs24-xs fs32 tal mb2 gray800">
        <Trans>
          Looking for COVID-19
          <br /> Travel Clearance testing?
        </Trans>
      </h1>
      <p className="fs14-xs tal mb2 gray800">
        {i18n.t(
          "Book a COVID-19 travel clearance test to travel safely. Schedule your test today.",
        )}
      </p>
      <div className="df mt4 mt2-xs fw ">
        {modalProps.buttons.map((b, i) => (
          <Btn className="fs14-f fs12-f-xs mr2 mh2-xs mb2-xs" key={`modalBtn-${i}`} {...b} />
        ))}
      </div>
    </div>
  );

  // @ts-expect-error TS2322: Type 'string | boolean | undefined' is not assignable to type 'boolean'.
  return {showTravelModal, modalImage: "/covidTravel/travel-clearance-modal", modalInner};
};
