import {colors} from "@c10h/colors";
import {NavLanguageSelector} from "@components/Nav/NavLanguageSelector";
import {trackNavLinkClick} from "@components/Nav/utils";
import {FocusRing} from "@react-aria/focus";
import Link from "next/link";
import {useTranslation} from "ni18n";
import React, {useState} from "react";
import {useHeaderConstants} from "src/constants/NavConstants";

import {getLoginRedirectUrl, getSignupRedirectUrl} from "../../../utils/getRedirectUrl";
import {
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  twitterUrl,
  v5Pages,
} from "../../_common/_constants";
import Fade from "../Transitions/Fade";
import DrawerNavAnchor from "./DrawerNavAnchor";

type Props = {
  closeDrawer: () => void;
};

const Drawer: React.FC<Props> = ({closeDrawer}) => {
  const i18n = useTranslation();

  const {
    config: {drawer},
  } = useHeaderConstants();

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleOpenIndex = (i: number) => () => {
    if (openIndexes.includes(i)) {
      setOpenIndexes(openIndexes.filter(x => x !== i));
    } else {
      setOpenIndexes([...openIndexes, i].distinct());
    }
  };

  return (
    <div className="df fdc pt4 bg-white h100vh" data-cy="drawer">
      <FocusRing focusRingClass="focus-ring">
        <button
          className="pos-a right0 top0 mt3 mr3 br50 brdn cp zIndex2 aria-focus"
          aria-label={i18n.t("Close Menu")}
          style={{height: "2.5rem", width: "2.5rem"}}
          onClick={() => {
            closeDrawer();
            trackNavLinkClick("Menu", "Menu", "Close Menu", "");
          }}
        >
          <span className="cIcon-close fs12" aria-hidden />
        </button>
      </FocusRing>

      {/* LANGUAGE SELECTOR */}
      <NavLanguageSelector
        renderButton={({selectedItem, ...rest}) => (
          <button
            {...rest}
            className="br8 ph4 pv3 brdn fs14 lh1 cp bg-transparent df aic ph10 aria-focus"
            onClick={() => trackNavLinkClick("Languages", "Menu", selectedItem, selectedItem)}
            data-cy="lng-selector"
          >
            <span className="cIcon-globe fs16" aria-hidden />
            <span className="ph2 font-im gray800">{selectedItem}</span>
            <span className="cIcon-icon_arrow_dropdown fs16" aria-hidden />
          </button>
        )}
      />

      <div className="ph10 pt5">
        {/* UPPER */}
        <div className="pb8">
          <h2 className="visually-hidden">{i18n.t("Site Navigation")}</h2>
          <h3 className="ttu font-im fs13 lh18 gray400 ls2 pb3">{i18n.t("My Account")}</h3>
          <div className="dg gg2 gafc wfc">
            <a
              className="focus-bsDarkBlue3 font-isb fs14 lh12 pv3 ph4 bg-gray800 br8 white"
              href={getLoginRedirectUrl()}
              onClick={() => trackNavLinkClick("Account", "Menu", "Log In", "My Account")}
            >
              {i18n.t("Log In")}
            </a>
            <a
              className="focus-bsDarkBlue3 font-isb fs14 lh12 pv3 ph4 gray800 br8"
              href={getSignupRedirectUrl()}
              style={{backgroundColor: `${colors.gray800}10`}}
              onClick={() => trackNavLinkClick("Account", "Menu", "Sign Up Free", "My Account")}
            >
              {i18n.t("Sign Up Free")}
            </a>
          </div>
        </div>

        {/* ACCORDION */}
        <nav aria-label={i18n.t("Services")}>
          {drawer.accordion.map(({title, sub, id, label}, i) => {
            const isOpen = openIndexes.includes(i);
            return (
              <div className="pb4" key={i}>
                <button
                  className="cp focus-bsDarkBlue3 t-150 wfc gray800 brdn bg-white db ph2"
                  aria-controls={id}
                  aria-expanded={isOpen}
                  tabIndex={0}
                  onClick={toggleOpenIndex(i)}
                  data-cy="drawer-parent-link"
                >
                  <div>
                    <span
                      className={`cIcon-plus2 fs14 dib pos-r t-tf100 tal ${
                        isOpen ? "rotate45" : ""
                      }`}
                      aria-hidden
                    />
                    <span className="fs20 lh14 font-cm ml3">{title}</span>
                  </div>
                </button>
                <Fade in={isOpen} unmountOnExit duration={100} timeout={0}>
                  <ul className="ml2 pt2 ph2" id={id} data-cy={`drawer-${id}`}>
                    {sub.map(link => (
                      <DrawerNavAnchor
                        key={link.title}
                        {...link}
                        closeDrawer={closeDrawer}
                        // @ts-expect-error TS2345: Argument of type 'NavLabel | undefined' is not assignable to parameter of type 'NavLabel'.
                        onClick={() => trackNavLinkClick(label, "Menu", link.title, title)}
                      />
                    ))}
                  </ul>
                </Fade>
              </div>
            );
          })}
          <Link
            href={v5Pages.locations}
            onClick={() =>
              trackNavLinkClick("Locations", "Menu", "Find a Location", "Find a Location")
            }
            className="focus-bsDarkBlue3 db"
            data-cy="drawer-parent-link"
          >
            <span className="df aic ml2">
              <span className="cIcon-bigRightArrow dib fs14 mr3 gray800" aria-hidden />
              <span className="fs20 lh14 font-cm gray800">{i18n.t("Find a Location")}</span>
            </span>
          </Link>
        </nav>

        {/* BOTTOM */}
        <div className="pt10 pb12 maxw26">
          <h3 className="ttu font-im ls2 fs13 gray400 pb4">{i18n.t("More")}</h3>
          <nav>
            <ul className="dg gafr font-cm gtc2fr cg12">
              {drawer.footer.map(item => (
                <li key={item.title}>
                  <Link
                    href={item.href}
                    className="focus-bsDarkBlue3 wfc mr8 wsnw font-im fs14 lh22 gray800"
                    onClick={() => trackNavLinkClick("Company", "Menu", item.title, "More")}
                    data-cy="footer-link"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div>
          <ul className="df fs20">
            <li className="pr10">
              <a
                href={instagramUrl}
                style={{color: `${colors.gray800}50`}}
                className="cIcon-instagram"
                onClick={() => trackNavLinkClick("Company", "Menu", "Instagram", "")}
              >
                <span className="visually-hidden">Instagram</span>
              </a>
            </li>
            <li className="pr10">
              <a
                href={twitterUrl}
                style={{color: `${colors.gray800}50`}}
                className="cIcon-twitter"
                onClick={() => trackNavLinkClick("Company", "Menu", "Twitter", "")}
              >
                <span className="visually-hidden">Twitter</span>
              </a>
            </li>
            <li className="pr10">
              <a
                href={facebookUrl}
                style={{color: `${colors.gray800}50`}}
                className="cIcon-facebook"
                onClick={() => trackNavLinkClick("Company", "Menu", "Facebook", "")}
              >
                <span className="visually-hidden">Facebook</span>
              </a>
            </li>
            <li className="pr10">
              <a
                href={linkedInUrl}
                style={{color: `${colors.gray800}50`}}
                className="cIcon-linkedin"
                onClick={() => trackNavLinkClick("Company", "Menu", "LinkedIn", "")}
              >
                <span className="visually-hidden">LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
