import {colors} from "@c10h/colors";
import NextLink from "@components/NextLink";
import React, {useState} from "react";
import {DrawerItem} from "src/constants/NavConstants";

const DrawerNavAnchor: React.FC<DrawerItem & {closeDrawer: () => void}> = ({
  title,
  href,
  as,
  newTab,
  external,
  ariaLabel,
  closeDrawer,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);

  const toggleHovered = (boolean?: boolean) => () => {
    if (typeof boolean === "boolean") {
      setHovered(boolean);
    } else {
      setHovered(!hovered);
    }
  };
  return (
    <li
      key={title}
      className="gray800 hover-darkGreen fs16 lh22"
      onClick={e => {
        e.stopPropagation();
        closeDrawer();
        // @ts-expect-error TS2722: Cannot invoke an object which is possibly 'undefined'.
        onClick();
      }}
      onMouseEnter={toggleHovered(true)}
      onMouseLeave={toggleHovered(false)}
    >
      <NextLink {...{as, href, external}}>
        <a
          aria-label={ariaLabel || title}
          className="focus-bsDarkBlue3 dif aic"
          onFocus={toggleHovered(true)}
          onBlur={toggleHovered(false)}
          {...(newTab && {target: "_blank", rel: "noopener noreferrer"})}
          {...(external && ({href} as {href: string}))}
          data-cy="drawer-child-link"
        >
          <svg
            height={6}
            width={6}
            className="t-150"
            style={{visibility: hovered ? "visible" : "hidden", opacity: hovered ? 1 : 0}}
          >
            <circle cx="50%" cy="50%" r="45%" strokeWidth="3" fill={colors.green} />
          </svg>
          <span className="ml3 font-c" style={{color: hovered ? colors.green : colors.gray800}}>
            {title}
          </span>
        </a>
      </NextLink>
    </li>
  );
};

export default DrawerNavAnchor;
