import React, {memo, useRef} from "react";
import {Transition} from "react-transition-group";
import {TransitionProps} from "react-transition-group/Transition";
import {usePrefersReducedMotion} from "src/hooks/usePrefersReducedMotion";

type Props = Omit<TransitionProps<HTMLDivElement>, "addEventListener"> & {
  in: boolean;
  appear?: boolean;
  collapse?: boolean;
  duration?: number;
  timeout?: number;
};

const maxHeight = 5000;
const maxWidth = maxHeight;

const Fade: React.FC<Props> = ({
  appear,
  collapse,
  timeout = 10,
  duration = 200,
  id,
  in: isIn,
  ...props
}) => {
  const shouldReduceMotion = usePrefersReducedMotion();
  const defaultStyle = {
    transition: `all ${shouldReduceMotion ? 0 : duration}ms ease-in-out`,
    opacity: 0,
  };

  const mounted = {
    opacity: 1,
    maxHeight,
    maxWidth,
  };
  const unmounted = {
    opacity: 0,
    maxHeight: collapse ? 0 : maxHeight,
    maxWidth: collapse ? 0 : maxWidth,
  };

  const transitionStyles = {
    entering: unmounted,
    entered: mounted,
    exiting: mounted,
    exited: unmounted,
  };

  const nodeRef = useRef(null);

  return (
    <Transition nodeRef={nodeRef} in={isIn} timeout={timeout} appear={appear} {...props}>
      {state => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'TransitionStatus' can't be used to index type '{ entering: { opacity: number; maxHeight: number; maxWidth: number; }; entered: { opacity: number; maxHeight: number; maxWidth: number; }; exiting: { opacity: number; maxHeight: number; maxWidth: number; }; exited: { ...; }; }'.
            ...transitionStyles[state],
          }}
          id={id}
        >
          {props.children}
        </div>
      )}
    </Transition>
  );
};

export default memo(Fade);
