import Link, {LinkProps} from "next/link";
import React, {ReactElement} from "react";

const NextLink = ({
  children,
  external,
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  className,
  passHref,
}: {
  children: ReactElement;
  external?: boolean;
  href: LinkProps["href"];
  as?: LinkProps["as"];
  replace?: LinkProps["replace"];
  scroll?: LinkProps["scroll"];
  shallow?: LinkProps["shallow"];
  prefetch?: LinkProps["prefetch"];
  className?: string;
  passHref?: boolean;
}) =>
  external ? (
    children
  ) : (
    <Link {...{href, as, replace, scroll, shallow, prefetch, className, passHref}} legacyBehavior>
      {children}
    </Link>
  );
export default NextLink;
