import TreeView from "@components/components/Tree";
import {ButtonVariant} from "@components/components/Tree/types";
import {compact, sortBy} from "lodash";
import {useTranslation} from "ni18n";
import React, {useCallback, useMemo} from "react";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";

import {actions, useTypedDispatch, useTypedSelector} from "../../../store";
import {getRegionSlug} from "../../../store/getRegionSlug";
import {selectSelectedRegion} from "../../../store/slices/userLocation";
import {RegionSlug} from "../../../store/types";
import {generateRegionData} from "../../../utils/regionUtils";
import {getStateCodeFromRegionSlug} from "../../../utils/stateUtils";
import {TreeItem} from "../components/Tree/ComplexTreeWrapper";
import {
  sendAnalyticsDataOnRegionChange,
  sendAnalyticsDataOnSearch,
} from "./utils/handleSendAnalyticsData";

interface Region {
  data: string;
  id: string;
}

const sfBayAreaRegion = {
  slug: getRegionSlug("sf-bay-area"),
  name: "Bay Area",
};

const isCARegion = (slug: string) => getStateCodeFromRegionSlug(slug) === "CA";

const buildRegionTree =
  (regions: Region[]) =>
  (searchTerm: string): TreeItem<string> => {
    const filteredRegions = regions.filter(({data}) =>
      data.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const filteredCARegions: TreeItem<string>[] = filteredRegions
      .filter(region => isCARegion(region.id))
      .map(region => ({
        id: region.id,
        data: region.data,
      }));

    const californiaParentItem: TreeItem<string> | undefined =
      filteredCARegions.length > 0
        ? {
            id: "ca",
            data: "California",
            children: filteredCARegions,
          }
        : undefined;

    const otherRegions = filteredRegions.filter(({id}) => !isCARegion(id));

    return {
      id: "root",
      children: sortBy(
        compact([
          californiaParentItem,
          ...otherRegions.map(region => ({
            id: region.id,
            data: region.data,
          })),
        ]),
        "data",
      ),
      data: "Root",
    };
  };

interface RegionTreeSelector {
  buttonVariant?: ButtonVariant;
  initialRegionSlug?: RegionSlug;
  onSelect?: (slug: RegionSlug) => void;
  hideVirtualOnly?: boolean;
  inClinicSpecialtyIds?: string[];
}

const RegionTreeSelector: React.FC<RegionTreeSelector> = ({
  buttonVariant = "primary",
  onSelect,
  initialRegionSlug,
  inClinicSpecialtyIds,
  hideVirtualOnly = false,
}) => {
  const i18n = useTranslation();
  const dispatch = useTypedDispatch();
  const selectedFromStore = useTypedSelector(selectSelectedRegion) ?? "sf-bay-area";
  const {locations} = useTypedSelector(({config}) => config);
  const closestRegion = locations[0]?.region || sfBayAreaRegion;

  const selectedRegion = initialRegionSlug ?? selectedFromStore;
  const currentRoute = useCurrentRoute();

  const selectorName = i18n.t("Region");
  const searchLabel = i18n.t("Search by region name");
  const searchPlaceholder = i18n.t("Search regions");

  const regions = useMemo(
    () => generateRegionData(locations, inClinicSpecialtyIds, hideVirtualOnly),
    [hideVirtualOnly, inClinicSpecialtyIds, locations],
  );

  const expandedItems = useMemo(
    () => (getStateCodeFromRegionSlug(selectedFromStore) === "CA" ? ["ca"] : []),
    [selectedFromStore],
  );

  const items = useMemo(() => regions.map(({slug, name}) => ({data: name, id: slug})), [regions]);

  const initialSelected = useMemo(
    () =>
      items.find(({id}) => id === selectedRegion) || {
        id: closestRegion.slug,
        data: closestRegion.name,
      },
    [closestRegion.name, closestRegion.slug, items, selectedRegion],
  );

  const handleSelect = useCallback(
    (slug: string | number) => {
      if (typeof slug === "string") {
        if (selectedFromStore !== slug)
          dispatch(actions.setUserLocation({selectedRegion: slug as RegionSlug}));
        if (onSelect) onSelect(slug as RegionSlug);
        sendAnalyticsDataOnRegionChange(slug, currentRoute);
      }
    },
    [currentRoute, dispatch, onSelect, selectedFromStore],
  );

  const buildTree = useMemo(() => buildRegionTree(items), [items]);

  return (
    <TreeView
      buttonVariant={buttonVariant}
      selectorName={selectorName}
      searchLabel={searchLabel}
      searchPlaceholder={searchPlaceholder}
      initialSelected={initialSelected}
      initialExpandedItems={expandedItems}
      handleSelect={handleSelect}
      handleSearch={sendAnalyticsDataOnSearch}
      buildTree={buildTree}
    />
  );
};

export default React.memo(RegionTreeSelector);
