import {colors} from "@c10h/colors";
import {Btn} from "@components/V5Comp";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useCallback, useMemo} from "react";
import {v5Pages} from "src/components/_common/_constants";
import {RootStateLocation} from "src/store/types";
import {analytics} from "src/utils/analytics";
import {GetOpenTimeResult} from "src/utils/timeUtils";

import {SoonestSlotTimeByLocationAndSpecialty} from "../../../hooks/useGetSoonestTime";
import SpecialtyAndTime from "./SpecialtyAndTime";

type Props = GetOpenTimeResult & {
  location: RootStateLocation;
  hideButton?: boolean;
  isMapPopup?: boolean;
  isMobileList?: boolean;
  disableButton?: boolean;
  daysFromToday?: number;
  specialtyBoxMode?: boolean;
  soonestSlots?: SoonestSlotTimeByLocationAndSpecialty;
  buttonText?: string;
  condensed?: boolean;
};

const LocationDetailsCard: React.FC<Props> = ({
  location,
  timeString,
  timeBlock,
  isOpenNow,
  hideButton,
  isMapPopup,
  disableButton,
  specialtyBoxMode,
  soonestSlots,
  buttonText,
  condensed = "true",
}) => {
  const i18n = useTranslation();
  const {pathname} = useRouter();

  const {name, yelpRating, dist, specialties, address} = location;

  // Will not matter for ClinicList in ListView/Map toggle experiment because button is hidden
  const btnProps = useMemo(
    () =>
      !disableButton
        ? {
            href: {
              pathname: v5Pages.clinicDetails,
              query: {slug: location.slug},
            },
            link: true,
          }
        : {
            type: "link", // For valid DOM nesting
            faux: true,
          },
    [disableButton, location.slug],
  );

  const onClick = useCallback(() => {
    // Will not fire for ClinicList in ListView/Map toggle experiment because button is hidden
    if (!disableButton) {
      analytics.post({
        category: "LocationDiscovery",
        action: "Clicked",
        label: "SelectClinic",
        extraData: {
          locationId: location.id,
          milesAway: location.dist,
          openNow: isOpenNow,
          isMapPopup,
          source: pathname === v5Pages.locations ? "index" : "footer",
        },
      });
    }
  }, [disableButton, isMapPopup, isOpenNow, location.dist, location.id, pathname]);

  const orderedSpecialties = specialties
    .vals()
    .filter(s => !s.isVirtual)
    .sortBy(s => s.sortIndex);

  return (
    <div className="fs14 zIndex1 bg-transparent">
      <div className="df fdc jcsb pos-r hide-scroll-bars">
        <div className="mb2 lh12">
          <h3
            className={`font-csb-f fw5-f mb1 ${
              condensed ? "maxw18 maxw12-xs" : ""
            } fs18 minw18 minw0-xs font-isb`}
            data-cy="location-name"
          >
            {name}
          </h3>
          <div className="mt1">
            <span className="font-isb">
              {yelpRating && (
                <>
                  <span className="cIcon-star darkYellow mr1" aria-hidden />
                  <span className="visually-hidden">{i18n.t("Star Rating:")}</span>
                  <span>{yelpRating}</span>
                  {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
                  {dist > -1 && (
                    <span className="mh1" aria-hidden>
                      ·
                    </span>
                  )}
                </>
              )}
              {timeBlock && (
                <strong className={`font-isb ${!isOpenNow ? "gray800-f" : "darkGreen-f mr1"}`}>
                  {isOpenNow ? i18n.t("Open") : null}
                </strong>
              )}
              <span className="gray600 font-ir">{timeString}</span>
            </span>
          </div>

          <div className="gray600 font-ir minh8 ls-3 pb2 df fw maxw16-xs">
            <span>{address.firstLine}</span>
            {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
            {dist > -1 && (
              <span className="mh1" aria-hidden>
                ·
              </span>
            )}
            {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
            {dist > -1 && <span>{i18n.t("{{number}} mi away", {number: dist})}</span>}
          </div>
        </div>

        <div className="df fdc ovf-x-a" style={{maxWidth: specialtyBoxMode ? "90vw" : "80vw"}}>
          <ul className={`mb2 dg gg2 hide-in-percy ${specialtyBoxMode ? "gafc" : "gafr"}`}>
            {orderedSpecialties.map(s => (
              <li key={location.id + s.id}>
                <SpecialtyAndTime
                  location={location}
                  specialty={s}
                  boxMode={specialtyBoxMode}
                  soonestSlots={soonestSlots}
                />
              </li>
            ))}
            {specialtyBoxMode && <li className="minw2" aria-hidden />}
          </ul>

          {!hideButton && (
            <Btn
              fontSize={0.75}
              className="mt2 mb1 focus-bsDarkBlue3-hug"
              {...btnProps}
              onClick={onClick}
              // @ts-expect-error TS2322: Type 'string | null' is not assignable to type 'string | undefined'.
              color={isMapPopup ? colors.gray800 : null}
              // @ts-expect-error TS2322: Type 'string | null' is not assignable to type 'string | undefined'.
              bg={isMapPopup ? colors.gray100 : null}
              // @ts-expect-error TS2322: Type 'string | null' is not assignable to type 'string | undefined'.
              hoverBg={isMapPopup ? colors.gray100 : null}
              aria-label={i18n.t("Select Clinic: {{location}}", {location: location.name})}
            >
              {buttonText || i18n.t("Select Clinic")}
            </Btn>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationDetailsCard;
