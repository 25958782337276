import hardcodedVirtualRegions from "./hardcodedVirtualRegions";

const hardcodedMap = hardcodedVirtualRegions.reduce<Record<string, {state: string; code: string}>>(
  (acc, next) => ({
    ...acc,
    [next.slug]: {
      state: next.name,
      code: next.statePostalAbbreviation,
    },
  }),
  {},
);

export const RegionStateMap: Record<string, {state: string; code: string}> = {
  arizona: {
    state: "Arizona",
    code: "AZ",
  },
  colorado: {
    state: "Colorado",
    code: "CO",
  },
  connecticut: {
    state: "Connecticut",
    code: "CT",
  },
  florida: {
    state: "Florida",
    code: "FL",
  },
  georgia: {
    state: "Georgia",
    code: "GA",
  },
  "greater-san-diego": {
    state: "California",
    code: "CA",
  },
  illinois: {
    state: "Illinois",
    code: "IL",
  },
  kansas: {
    state: "Kansas",
    code: "KS",
  },
  "los-angeles": {
    state: "California",
    code: "CA",
  },
  massachusetts: {
    state: "Massachusetts",
    code: "MA",
  },
  michigan: {
    state: "Michigan",
    code: "MI",
  },
  "new-jersey": {
    state: "New Jersey",
    code: "NJ",
  },
  "north-carolina": {
    state: "North Carolina",
    code: "NC",
  },
  ohio: {
    state: "Ohio",
    code: "OH",
  },
  pennsylvania: {
    state: "Pennsylvania",
    code: "PA",
  },
  nevada: {
    state: "Nevada",
    code: "NV",
  },
  sacramento: {
    state: "California",
    code: "CA",
  },
  washington: {
    state: "Washington",
    code: "WA",
  },
  "sf-bay-area": {
    state: "California",
    code: "CA",
  },
  "inland-empire": {
    state: "California",
    code: "CA",
  },
  "orange-county": {
    state: "California",
    code: "CA",
  },
  "san-luis-obispo": {
    state: "California",
    code: "CA",
  },
  texas: {
    state: "Texas",
    code: "TX",
  },
  virginia: {
    state: "Virginia",
    code: "VA",
  },
  ...hardcodedMap,
};
