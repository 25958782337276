import {sortBy} from "lodash";

import {Region} from "../../../../store/types";

export type LocationFragment = {
  region: Region;
  specialtyIds: string[];
};

export const getRegionsFromLocationsBySpecialtyIds = (
  locations: LocationFragment[],
  /**
   * Does not filter if undefined.
   */
  specialtyIds?: string[],
): Region[] => {
  const uniqueLocationsBySlug = locations.reduce<Record<string, Region>>((acc, next) => {
    const isSpecialtyMatch = specialtyIds
      ? specialtyIds.some(id => next.specialtyIds.includes(id))
      : true;
    return isSpecialtyMatch
      ? {
          ...acc,
          [next.region.slug]: next.region,
        }
      : acc;
  }, {});

  return sortBy(Object.values(uniqueLocationsBySlug), r => r.name);
};
