import memoizee from "memoizee";

export interface Cities {
  city: string;
  state: string;
}

const _fetchCities = (): Promise<Cities[]> => fetch("/static/data/cities.json").then(r => r.json());

export const fetchCities = memoizee(_fetchCities, {promise: true});
